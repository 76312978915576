import { graphql } from 'gatsby';
import React from "react";

// Component Imports
import Layout from "../components/Layout";
import Hero from '../components/Hero';
import Content from "../components/Content";
import Book from "../components/Book";
import Origin from "../components/Origin";
import Video from "../components/Video";
import RatingsBanner from "../components/RatingsBanner";
import AwardsBanner from "../components/AwardsBanner";
import About from '../components/About';

const Index = ({ data }) => {

  const {
    hero, 
    content,
    book, 
    origin, 
    about,
    buttonText,
    footer,
    awards,
    ratings
  } = data.content.childContentJson;

  const buyUrl = "https://www.vinha.co.uk/wine/wine-box-foot-trodden-simon-j-woolf-ryan-opaz-portugal/"

  const navbarData = { about: about.title, buttonText, locale: "nl", buyUrl };

  const ninfa = content.whites.wines[0].image.childImageSharp.fluid;
  const lapa = content.reds.wines[0].image.childImageSharp.fluid;

  return (
    <Layout footerData={footer} navbarData={navbarData}>
      
      <Hero data={hero} ninfa={ninfa} lapa={lapa} locale="nl" />
      {/* <Video /> */}
      <Content data={content} buttonText={buttonText} buyUrl={buyUrl} />
      <Book data={book}/>
      {/* <Origin data={origin} locale="en" /> */}
      <AwardsBanner data={awards}/>
      <RatingsBanner data={ratings}/>
      <About data={about}/>

     
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    content: file(childContentJson: {locale: {eq: "nl"}}) {
      childContentJson {
        about {
          title
          text
        }
        book {
          title
          text
          quotes
        }
        buttonText
        content {
          disclaimer
          reds {
            title
            wines {
              description
              name
              subname
              featured
                image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          whites {
            title
            wines {
              description
              name
              subname
              featured
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        
        hero {
          curator
          curatorTitle
          subtitle
          text
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          rodolfo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          ronald {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        origin {
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        footer {
          terms_conditions
          logos {
            vinhos {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            wines {
              
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        awards {
          title {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          badge1 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          badge2 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          badge3 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ratings {
          rating {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          deliver {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          best {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default Index;


